<template>
    <section class="login-section">
        <div class="container-fluid">
            <div class="row">
                <div class="col col-md-7 col-block">
                    <div class="login-side-sec">
                        <div class="login-bg"></div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="login-holder">
                        <div class="login-block">
                            <div class="login-col">
                                <div id="register_wizard">
                                    <div v-if="currentStep === 1" class="fsm-state fsm-initial">
                                        <div class="step_img">
                                            <img src="../../assets/images/register_step_1.png" class="img-fluid"
                                                alt="Create Account" />
                                        </div>
                                        <h2 class="CreateLabelStyle">Create Account</h2>
                                        <div class="radio-group">
                                            <label class="radio_container">
                                                <input id="TrialRadioBtn" v-model="account_version" type="radio"
                                                    value="TRIAL" />
                                                <span class="radio-label">Trial<span> Version</span></span>
                                            </label>
                                            <label class="radio_container">
                                                <input id="FreeRadioBtn" v-model="account_version" type="radio"
                                                    value="FREE" />
                                                <span class="radio-label">Free<span> Version</span></span>
                                            </label>
                                        </div>
                                        <div class="tooltip-container">
                                            <div class="tooltip">
                                                <span>
                                                    FREE version has limited features.
                                                    <b>For evaluation of Nimbus, Trial version is recommended.</b>
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="account_version === 'FREE'" class="info">
                                            <p>
                                                Trial version is the best option for Nimbus evaluation.
                                                <a href="#" @click.prevent="toggleFreeVersionInfo" class="title">
                                                    <span class="animate-charcter">Show more</span>
                                                </a>
                                            </p>
                                            <div v-if="showFreeVersionInfo" class="extra-info">
                                                <p>
                                                    Both Multi Stores and Double Entry Accounting options are available
                                                    in Trial version only (not in FREE Version).
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="currentStep === 2" class="fsm-state select_business_type">
                                        <div class="step_img">
                                            <img src="../../assets/images/register_step_2.png" class="img-fluid"
                                                alt="Select Product" />
                                        </div>
                                        <h2 class="CreateLabelStyle">Select Product</h2>
                                        <div class="radio-group">
                                            <label class="radio_container">
                                                <input id="RetailRadioBtn" v-model="product" type="radio"
                                                    value="RETAIL" />
                                                <span class="radio-label">Nimbus<span> Retail</span></span>
                                            </label>
                                            <label class="radio_container">
                                                <input id="RestaurantRadioBtn" v-model="product" type="radio"
                                                    value="restaurant" />
                                                <span class="radio-label">Nimbus<span> Restaurant</span></span>
                                            </label>
                                        </div>
                                        <div class="col-md-6 form-group" id="ddlStoreSaleDiv"
                                            v-if="product === 'RETAIL'">
                                            <select v-model="business_type" class="form-control">
                                                <option value="" disabled>Select Your Business Type</option>
                                                <option value="Footwear">Footwear</option>
                                                <option value="Garments">Garments</option>
                                                <option value="Grocery">Grocery/Other</option>
                                                <option value="AutoPart">Auto Parts</option>
                                            </select>
                                            <span v-if="business_typeError" class="error-message">{{ business_typeError
                                                }}</span>
                                        </div>
                                    </div>

                                    <div v-if="currentStep === 3" class="fsm-state">
                                        <div class="step_img">
                                            <img src="../../assets/images/register_step_3.png" class="img-fluid"
                                                alt="Select Store" />
                                        </div>
                                        <h2 class="CreateLabelStyle">Select Store</h2>
                                        <div class="radio-group">
                                            <label class="radio_container">
                                                <input type="radio" v-model="store" value="SINGLE" />
                                                <span class="radio-label">Single<span> Store</span></span>
                                            </label>
                                            <label class="radio_container">
                                                <input type="radio" v-model="store" value="MULTI" />
                                                <span class="radio-label">Multi<span> Store</span></span>
                                            </label>
                                            <div v-if="store === 'MULTI'" class="message">
                                                <span class="RegisterBodyh1">
                                                    Multi Stores option is not available in free version.
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="currentStep === 4" class="fsm-state fsm-final">
                                        <div class="step_img">
                                            <img src="../../assets/images/register_step_4.png" class="img-fluid"
                                                alt="Fill Out the Form" />
                                        </div>
                                        <h2 class="RegisterBodyh1">Nearly there!<span class="RegisterBodyh1span"> Please
                                                Fill Out the Form</span></h2>
                                        <div class="Registerform-box">
                                            <div class="form-group">
                                                <label for="CCompany" class="label block-label">Company Name</label>
                                                <input v-model="company" type="text" id="CCompany"
                                                    class="form-control" />
                                                <span v-if="formErrors.company" class="error-message">{{
                                                    formErrors.company }}</span>
                                            </div>
                                            <div class="form-group">
                                                <label for="CEmail" class="label block-label">Email</label>
                                                <input v-model="email" type="email" id="CEmail" class="form-control" />
                                                <span v-if="formErrors.email" class="error-message">{{ formErrors.email
                                                    }}</span>
                                            </div>
                                            <div class="form-group">
                                                <label for="CPass" class="label block-label">Password</label>
                                                <input v-model="password" type="password" id="CPass"
                                                    class="form-control" />
                                                <span v-if="formErrors.password" class="error-message">{{
                                                    formErrors.password }}</span>
                                            </div>
                                            <div class="form-group">
                                                <label for="CPhn" class="label block-label">Phone</label>
                                                <input v-model="phone" type="text" id="CPhn" maxlength="15"
                                                    class="form-control" />
                                                <span v-if="formErrors.phone" class="error-message">{{ formErrors.phone
                                                    }}</span>
                                            </div>
                                            <div class="form-group">
                                                <label for="CCountry" class="label block-label">Country</label>
                                                <select v-model="country" class="form-control" id="CCountry">
                                                    <option value="" disabled>Select Country</option>
                                                    <option value="USA">USA</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="UK">UK</option>
                                                </select>
                                                <span v-if="formErrors.country" class="error-message">{{
                                                    formErrors.country }}</span>
                                            </div>
                                            <div class="form-group">
                                                <label>
                                                    <input type="checkbox" v-model="termsAccepted" />
                                                    I agree to the <a href="#" class="terms-link">Terms and
                                                        Conditions</a>
                                                </label>
                                                <span v-if="!termsAccepted && currentStep === 4"
                                                    class="error-message">You must agree to the terms and
                                                    conditions.</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="wizard-navigation">
                                        <button @click="prevStep" v-if="currentStep > 1">Previous</button>
                                        <button @click="nextStep" v-if="currentStep < 4">Next</button>
                                        <button @click="submitForm" v-if="currentStep === 4"
                                            :disabled="!termsAccepted || isDisabled">Submit</button>
                                    </div>
                                </div>
                                <div v-if="loader" class="loader">Loading...</div>
                                <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { API } from "@/services"; // Ensure you have the correct import path
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            loader: false,
            currentStep: 1,
            account_version: "TRIAL",
            product: "RETAIL",
            store: "SINGLE",
            business_type: "",
            company: "",
            email: "",
            password: "",
            phone: "",
            country: "",
            isDisabled: false,
            termsAccepted: false,
            showFreeVersionInfo: false,
            storeError: "",
            formErrors: {
                company: "",
                email: "",
                password: "",
                phone: "",
                country: "",
            },
            successMessage: "",
        };
    },
    methods: {
        toggleFreeVersionInfo() {
            this.showFreeVersionInfo = !this.showFreeVersionInfo;
        },
        nextStep() {
            if (this.currentStep === 2 && this.product === "RETAIL") {
                if (!this.store) {
                    this.storeError = "Please select a value from the dropdown.";
                    return;
                } else {
                    this.storeError = "";
                }
            }

            if (this.currentStep === 4) {
                this.validateForm();
                if (Object.values(this.formErrors).some((error) => error)) {
                    return;
                }
            }

            if (this.currentStep < 4) {
                this.currentStep++;
            }
        },
        prevStep() {
            if (this.currentStep > 1) {
                this.currentStep--;
            }
        },
        submitForm() {
            this.validateForm();
            if (Object.values(this.formErrors).every((error) => !error) && this.termsAccepted) {
                let vm = this;
                vm.isDisabled = true;
                this.loader = true; // Start loader

                API.endpoints.auth.register({
                    account_version: this.account_version,
                    product: this.product,
                    store: this.store,
                    company: this.company,
                    email: this.email,
                    password: this.password,
                    phone: this.phone,
                    country: this.country,
                    business_type: this.business_type,
                })
                    .then((data) => {
                        // Show success message
                        Swal.fire({
                            icon: 'success',
                            title: 'Registration Successful',
                            text: 'Your account has been created!',
                        });
                        
                        window.location.href = '/login';
                        // Reset form or navigate to another page as needed
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 422) {
                            // Handle validation errors
                            const errors = error.response.data.errors;
                            this.formErrors = {
                                company: errors.company ? errors.company[0] : "",
                                email: errors.email ? errors.email[0] : "",
                                password: errors.password ? errors.password[0] : "",
                                phone: errors.phone ? errors.phone[0] : "",
                                country: errors.country ? errors.country[0] : "",
                                store: errors.store ? errors.store[0] : "",
                                account_version: errors.account_version ? errors.account_version[0] : "",
                                product: errors.product ? errors.product[0] : "",
                                business_type: errors.business_type ? errors.business_type[0] : "",
                            };
                        } else {
                            // Handle other errors
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: 'An unexpected error occurred.',
                            });
                        }
                    })
                    .finally(() => {
                        vm.loader = false; // Stop loader
                        vm.isDisabled = false;
                    });
            }
        },
        validateForm() {
            this.formErrors = {
                company: this.company ? "" : "Company Name is required.",
                email: this.email ? "" : "Email is required.",
                password: this.password ? "" : "Password is required.",
                phone: this.phone ? "" : "Phone is required.",
                country: this.country ? "" : "Country is required.",
            };
        },
    },
};
</script>

<style scoped>
.register_body {
    margin: 0 auto;
    max-width: 600px;
    padding: 20px;
}

.step_img {
    text-align: center;
    margin-bottom: 20px;
}

.CreateLabelStyle {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.radio-group {
    margin-bottom: 20px;
}

.radio_container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.error-message {
    color: red;
    font-size: 12px;
}

.wizard-navigation {
    text-align: center;
    margin-top: 20px;
}

.wizard-navigation button {
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.wizard-navigation button:hover {
    background-color: #0056b3;
}

.tooltip-container {
    margin: 10px 0;
}

.tooltip {
    font-size: 12px;
    color: #666;
}

.loader {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
}

.success-message {
    color: green;
    text-align: center;
    margin-top: 20px;
}
</style>

<template>
  <!-- Login Section -->
  <section class="login-section">
    <div class="container-fluid">
      <div class="row">
        <div class="col col-md-8 col-block">  
          <div class="login-side-sec">
            <div class="login-bg"> 
            </div>
          </div> 
        </div> 
        <div class="col-md-4 ">
          <div class="login-holder"> 
            <div class="login-block">
              <div class="login-col">
                <h2>Login</h2>
                <span class="title">Please sign in to access your data</span> 
                <div
                    class="error-message"
                    v-if="api.login.error_message"
                >
                  <p class="text-danger" style="font-size:11px !important;margin-bottom: 5px;">{{ api.login.error_message }}</p>
                </div> 
                <div v-if="screen==1"> 
                  <!-- form group -->
                  <div class="form-group">
                    <label class="label block-label"
                        for="company"
                    > 
                      Company
                    </label>
                    <input 
                      id="company" 
                      name="company" 
                      @keyup.enter="login()"
                      :disabled="isDisabled"
                      type="company" 
                      v-model="forms.login.company" 
                      class="form-control" 
                      autocomplete="off" 
                      placeholder="Enter Company Name" 
                      required 
                    />

                    <span class="text-danger" v-if="validations.company">{{validations.company[0]}}</span>

                  </div>

                  <div class="form-group">
                    <label class="label block-label"
                        for="email"
                    > 
                      Email/User ID
                    </label>
                    <input 
                      id="email" 
                      name="email" 
                      @keyup.enter="login()"
                      :disabled="isDisabled"
                      type="email" 
                      v-model="forms.login.username" 
                      class="form-control" 
                      autocomplete="off" 
                      placeholder="Enter Email/User ID" 
                      required 
                    />

                    <span class="text-danger" v-if="validations.username">{{validations.username[0]}}</span>

                  </div>

                  <div class="form-group">
                    <label 
                      class="label block-label"
                      for="password"
                    >
                      Password
                    </label>
                    <input
                        id="password"
                        name="password"
                        type="password"
                        @keyup.enter="login()"
                        v-model="forms.login.password"
                        class="form-control"
                        autocomplete="off"
                        placeholder="Enter Password"
                        required
                    />

                    <span class="text-danger" v-if="validations.password">{{validations.password[0]}}</span>

                  </div>
                  <!-- form group -->
                </div> 

                <!-- sign in -->
                <div class="mb-0" v-if="countdown>0">
                  <button 
                    @click="login()" 
                    :disabled='isDisabled' 
                    class="btn btn-primary active mb-3"
                    >
                      Login
                  </button>
                  <!-- <RouterLink to="/forgot-password" class="back-to-login"> 
                     Forgot Password
                  </RouterLink> -->
                  </br></br>
                  <RouterLink to="/register" class="back-to-login"> 
                    Don't have an account? Create Account
                  </RouterLink>
                </div>  

                <div v-if="screen == 3">
                  <p class="text-primary" style="font-size:11px !important;margin-bottom: 5px;">Your Password has been Expired , Please set new Password</p>
                  <div class="form-group">
                    
                    <input id="email" name="email" type="hidden" v-model="formData.email" class="form-control" />
                  </div>

                  <div class="form-group">
                    <label for="current_password"><b>Current Password</b><em>*</em></label>
                    <input id="current_password" name="current_password" type="password" v-model="formData.current_password" class="form-control" placeholder="*********" autocomplete="new-password" required />
                    <span class="text-danger" v-if="validations?.current_password">{{validations?.current_password[0]}}</span>
                 
                  </div>
                 
                  <div class="form-group">
                  
                    <label for="password"> <b> New Password</b> <em>*</em></label>
                    <input id="password" name="password" type="password" v-model="formData.password" class="form-control" placeholder="*********" autocomplete="off" required />

                    <span class="text-danger" v-if="validations?.password">{{validations?.password[0]}}</span>

                  </div>
                  <div class="form-group">
                    <label for="new_confirm_password"> <b>Confirm New Password </b> <em>*</em></label>
                    <input id="new_confirm_password" name="new_confirm_password" type="password" v-model="formData.new_confirm_password" class="form-control" placeholder="*********" autocomplete="off" required />

                    <span class="text-danger" v-if="validations?.new_confirm_password">{{validations?.new_confirm_password[0]}}</span>

                  </div>
                  <div class="mb-0">
                    <button @click="setPassword()" :disabled="isDisabled" class="btn btn-primary active">Set New Password</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
     
  </section>
</template>

<script>

import { LOGIN } from '@/store/actions'
import { mapActions } from 'vuex'
import {API} from "@/services";
import {Helpers} from "@/services/helpers.service";
import Swal from 'sweetalert2';

export default {

  name: 'LoginPage',


data(){
  return {
    loader: false,
    validations: {},
    countdown: 300000,
    currentPasswordError: '',
    invalidPasswordError: '',
    passwordError: '',
    forms: {
      login: {
        username: '',
        password: '',
        company: '',
        submitted: false,
      },
    },
    formData: {
        email: '',
        current_password: '',
        password: '',
        new_confirm_password: '',
       
      },
    isDisabled: null,
    currentDate: '',
    currentTime:'',
    screen:1,
    api: {
      login: { 
        send: false,
        error_message: '', 
        validation_errors: {}, 
      },
    },
  }
},
  computed: {
    Helpers() {
      return Helpers
    },
    formatTime() {
      const minutes = Math.floor(this.countdown / 60000);
      const seconds = ((this.countdown % 60000) / 1000).toFixed(0);
      // Add leading zeros if needed
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');
      return `${formattedMinutes}:${formattedSeconds}`;
    },
  },
methods: {
  restrictInput(event){
    console.log(event.target.id);
   let val=event.target.id;
   let vm=this;
    Helpers.verifyInputLength(event,4);
    this.otp= event.target.value;
  },
  startCountdown() {
    this.countdownInterval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown -= 1000; // Subtract 1 second (1000 milliseconds)
      } else {
        clearInterval(this.countdownInterval); // Stop the countdown when it reaches zero
      }
    }, 1000); // Update every 1 second (1000 milliseconds)
  },
  callFunction: function () {
    let date = new Date();
    const options = { weekday: 'long',month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    this.currentDate = formattedDate;
  },
  ...mapActions([
    LOGIN,
  ]),
  login() {
    let vm = this;
    vm.isDisabled = true;
    vm.validations =  {};
    
    vm.api.login.error_message = "";
    
    if(!vm.forms.login.username){
      vm.validations['company']        = ['The company field is required.'];
    }

    if(!vm.forms.login.username){
      vm.validations['username']        = ['The username field is required.'];
    }

    if(!vm.forms.login.password){
      vm.validations['password']        = ['The password field is required.'];
    }

    if(Object.keys(vm.validations).length!==0){
      vm.isDisabled=false;
      return false;
    }

    vm.loader  = true;

    if(vm.api.login.send) return;

    vm.api.login.send = true
    vm.api.login.error_message  = ''
    vm.api.login.validation_errors  = {}
    
    vm.LOGIN({
      username: vm.forms.login.username,
      password: vm.forms.login.password,
      company: vm.forms.login.company,
    })
    .catch((ex)=>{
      
      vm.api.login.error_message=ex?.own_message
      vm.api.login.validation_errors  = ex?.own_errors
    })
    .finally(()=>{
      vm.loader  = false;
      vm.isDisabled = false;
      vm.api.login.send=false
    });
      
  },
  setPassword() {
      
    this.validations =  {};
    if (this.formData.password !== this.formData.new_confirm_password) {
      this.validations['new_confirm_password'] = ['New password and confirm password do not match.'];
      return;
    }

    this.loader = true;
    this.isDisabled = true;
    this.isDisabled = true;

    API.endpoints.auth.updateuserpass(this.formData)
    .then((response) => {

      this.validations =  {};
      Swal.fire('Success!', 'New password updated successfully Please Login.', 'success');

      this.loader = false;
      this.isDisabled = false;
      this.screen = 1;
      this.countdown = 30000;
      
      this.invalidPasswordError = "" ;
      this.currentPasswordError = "" ;

      this.email = '' ;
      this.username = "";

    })
    .catch((ex) => {
      this.loader = false;
      this.isDisabled = false;

      if (!ex.response) {
          Swal.fire("Oops", "There was a problem connecting to the server. Please check your internet connection.", "warning");
        }
        else if (ex.response?.status == 422) {
          this.validations = ex?.response?.data?.errors;
        }
        else {
          Swal.fire("Oops", ex?.response?.data?.message || "An error occurred while processing your request.", "warning");
        }
        
    });
  },
},
mounted () { 
  
    this.callFunction(),
    setInterval(() => {
      let date = new Date();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const meridiem = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const timeStr = formattedHours + " : " + (minutes < 10 ? "0" : "") + minutes + " " + meridiem;
      this.currentTime = timeStr
    }, 1000)
  }
}
</script>
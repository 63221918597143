class LOG {
  text = 'PORTAL'
  enable = true
  /**
   * 
   * @param {Object} level 
   * @param  {...any} args 
   */
   consoleFun(level, ...args) {
    if(this.enable){
      const array = []
      array.push(`%c PORTAL::${this.text ? `${this.text.toUpperCase()}::` : ''}${level?.name?.toUpperCase?.() ?? ''}`)
      array.push(`background-color:${level.bg_color};color:${level.text_color};padding:5px;font-weight:bold;`)
      console.log(...array,...args)
    }
  }
  constructor(text,enable=true){
    this.text=text ?? ''
    this.enable = !!enable
  }
  warn(...args) { this.consoleFun({ name: 'warn', bg_color: 'yellow', text_color: 'black', },...args) }
  danger(...args) { this.consoleFun({ name: 'danger', bg_color: 'red', text_color: 'white' },...args) }
  success(...args) { this.consoleFun({ name: 'success', bg_color: 'green', text_color: 'white' },...args) }
  info(...args) { this.consoleFun({ name: 'info', bg_color: 'blue', text_color: 'white', },...args) }
  unique(...args) { this.consoleFun({ name: 'info', bg_color: 'pink', text_color: 'black', },...args) }
  event(...args) { this.consoleFun({ name: 'event', bg_color: 'purple', text_color: 'white', },...args) }
  watch(...args) { this.consoleFun({ name: 'watch', bg_color: 'wheat', text_color: 'black', },...args) }
  hook(...args) { this.consoleFun({ name: 'hook', bg_color: 'black', text_color: 'white', },...args) }
  default(...args) { this.consoleFun({ name: 'info', bg_color: 'white', text_color: 'black', },...args) }
  default1(...args) { this.consoleFun({ name: 'info', bg_color: 'orange', text_color: 'black', },...args) }
  default2(...args) { this.consoleFun({ name: 'info', bg_color: 'green', text_color: 'white', },...args) }
  log(...args) { this.consoleFun({ name: 'info', bg_color: '#F28B54', text_color: 'white', },...args) }
  log1(...args) { this.consoleFun({ name: 'info', bg_color: '#ffc107', text_color: 'black', },...args) }
  log2(...args) { this.consoleFun({ name: 'info', bg_color: '#7367f0', text_color: 'white', },...args) }
  connected(...args) { this.consoleFun({ name: 'connected', bg_color: '#57bbac', text_color: 'black', },...args) }
  disconnected(...args) { this.consoleFun({ name: 'disconnected', bg_color: '#290000', text_color: 'white', },...args) }
}
export const LOGGER = new LOG() 
export const API_LOGGER = new LOG('API',process.env.NODE_ENV!='production') 
<template>
  <AppHeader></AppHeader>
  <SideBar></SideBar>
  <div class="main-cotent">
     <router-view />
  </div>
</template>


<script> 
  import AppHeader from "@/layout/AppHeader";
  import SideBar from "@/layout/SideBar";
  export default {
    name: "layout",
    components: {
      AppHeader,
      SideBar
    }
  }
</script>
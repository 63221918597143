import router, { route_names, addRoutesAgainstUser } from "@/router";
import { API, STORAGE } from "@/services";
import { FETCH_USER, LOGIN, LOGOUT } from "../actions";
import { GET_TOKEN, GET_USER } from "../getters";
import { SET_TOKEN, SET_USER } from "../mutations";

export const auth = {
  state: {
    user: {},
    token: "",
  },
  getters: {
    [GET_USER](state) {
      return state.user;
    },
    [GET_TOKEN](state) {
      return state.token;
    },
  },
  actions: {
    [LOGIN]({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const useMock = false; // Flag to switch between real API and mock data
        if (useMock) {
          // Mock login response
          setTimeout(() => {
            STORAGE.TOKEN.set(mockLoginResponse.data.access_token);
            commit(SET_TOKEN, mockLoginResponse.data.access_token);
            resolve();
            dispatch("FETCH_USER");
          }, 500); // Simulate delay
        } else {
          // Real API call
          API.endpoints.auth
            .login({
              username: data?.username,
              password: data?.password,
              company: data?.company,
            })
            .then((response) => {
              STORAGE.TOKEN.set(response.data.access_token);
              commit(SET_TOKEN, response.data.access_token);
              resolve();
              dispatch("FETCH_USER");
            })
            .catch((ex) => {
              console.log(ex);
              reject(ex);
            });
        }
      });
    },
    [LOGOUT]({ commit }) {
      commit(SET_TOKEN);
      STORAGE.TOKEN.set("");
      STORAGE.USER.remove();
      commit(SET_USER);
      router.push({
        name: route_names.login,
      });
    },
    [FETCH_USER]({ commit }) {
      return new Promise((resolve, reject) => {
        const useMock = false; // Flag to switch between real API and mock data
        if (useMock) {
          // Mock fetch user response
          setTimeout(() => {
            STORAGE.USER.set(mockUserResponse.data);
            commit(SET_USER, mockUserResponse.data);
            addRoutesAgainstUser();
            resolve();
            router.push({
              name: route_names.home,
            });
          }, 500); // Simulate delay
        } else {
          // Real API call
          API.endpoints.user
            .getbyid()
            .then(({ data }) => {
              STORAGE.USER.set(data);
              commit(SET_USER, data);
              addRoutesAgainstUser();
              resolve();

              router.push({
                name: route_names.home,
              });
            })
            .catch((ex) => {
              reject(ex);
            });
        }
      });
    },
  },
  mutations: {
    [SET_USER](state, data) {
      state.user = data ?? {};
    },
    [SET_TOKEN](state, data) {
      state.token = data ?? "";
    }
  },
};

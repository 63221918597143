import Vue from "vue";
import {
  createWebHistory,
  createRouter
} from "vue-router";
import {
  STORAGE
} from "./services";
import store from "./store";

import layout from "@/views/app/Main.vue";

import Auth from "@/views/auth/Login.vue";
import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import Profile from "@/views/app/user/Profile.vue";

export const route_names = {
  home: "home",
  auth: "auth",
  login: "login",
  register: "register",
  dashboard: "dashboard",
  forgot_password: "forgot-password",
  company: "company",
  user: "user",
  main: "main",
  module: "module",
  permissions: "permissions",
  services: "services",
  reset_password: "reset-password",
  change_password: "change-password",
  set_password: "set-password",
  profile: "profile",
  department: "department",
  city: "city",
  cityArea: "cityArea",
  itemCodeTemplate: "itemCodeTemplate",
  itemCombination: "itemCombination",
  itemSize: "itemSize",
  itemGroup: "itemGroup",
  category: "category",
  subcategory: "subcategory",
  dbAttribute2: "dbAttribute2",
  dbAttribute1: "dbAttribute1",
  calendarSeason: "calendarSeason",
  gender: "gender",
  brand: "brand",
  ibAttribute2: "ibAttribute2",
  ibAttribute5: "ibAttribute5",
  ibAttribute6: "ibAttribute6",
  ibAttribute7: "ibAttribute7",
  itemDefinition: "itemDefinition",
  securityGroup: "securityGroup",
  users: "users",
  groupRight: "groupRight",
};

const router = createRouter({
  history: createWebHistory(),
  routes: [{
      name: route_names.auth,
      path: "/",
      component: Auth,
      redirect: "/login",
      meta: {
        not_require_auth: true,
        title: "Login",
      },
      children: [{
        path: "/login",
        name: route_names.login,
        component: Login,
      }, ],
    },
    {
      name: route_names.register,
      path: "/register",
      component: Register,
      redirect: "/register",
      meta: {
        not_require_auth: true,
        title: "Register",
      },
      children: [{
        path: "/register",
        name: route_names.register,
        component: Register,
      }, ],
    },
    {
      name: route_names.set_password,
      path: "/set-password",
      redirect: "/set-password",
      meta: {
        require_auth: true,
      },
      children: [{
        path: "/set-password",
        name: route_names.set_password,
        component: () => import("@/views/auth/SetPassword.vue"),
      }, ],
    },
    {
      name: route_names.forgot_password,
      path: "/forgot-password",
      redirect: "/forgot-password",
      meta: {
        not_require_auth: false,
      },
      children: [{
        path: "/forgot-password",
        name: route_names.forgot_password,
        component: () => import("@/views/auth/ForgotPassword.vue"),
      }, ],
    },
    {
      name: route_names.reset_password,
      path: "/reset-password/:token/:email",
      redirect: "/reset-password/:token/:email",
      meta: {
        not_require_auth: false,
      },
      children: [{
        path: "/reset-password/:token/:email",
        name: route_names.reset_password,
        component: () => import("@/views/auth/ResetPassword.vue"),
      }, ],
    },
    {
      name: route_names.change_password,
      path: "/change-password",
      component: layout,
      redirect: "/change-password",
      meta: {
        // can: 'dashboard',
        onDeniedRoute: "login",
        require_auth: true,
      },
      children: [{
        name: route_names.change_password,
        path: "/change-password",
        component: () => import("@/views/auth/ChangePassword.vue"),
      }, ],
    },
    {
      name: route_names.profile,
      path: "/profile/:id",
      redirect: "/profile/:id",
      component: layout,
      meta: {
        require_auth: true,
      },
      children: [{
        path: "/profile/:id",
        name: route_names.profile,
        component: () => import("@/views/app/user/UserAccountDetail.vue"),
      }, ],
    },
    {
      name: route_names.dashboard,
      path: "/dashboard",
      component: layout,
      redirect: "/dashboard",
      meta: {
        // can: 'dashboard',
        // onDeniedRoute: 'login',
        require_auth: true,
        title: "Dashboard",
      },
      children: [{
        name: route_names.dashboard,
        path: "/dashboard",
        component: () => import("@/views/app/dashboard/Dashboard"),
      }, ],
    },
    {
      name: route_names.home,
      path: "/",
      component: layout,
      redirect: "/home",
      meta: {
        require_auth: true,
        title: "Home",
      },
      children: [{
        name: route_names.home,
        path: "/home",
        component: () => import("@/views/app/Home"),
      }, ],
    },
    {
      name: route_names.user,
      path: "/user",
      component: layout,
      redirect: "/user",
      meta: {
        can: "users",
        onDeniedRoute: "login",
        require_auth: true,
        title: "User",
      },
      children: [{
        name: route_names.user,
        path: "/user",
        component: () => import("@/views/app/user/User"),
      }, ],
    },
    {
      name: route_names.company,
      path: "/company",
      component: layout,
      redirect: "/Company",
      meta: {
        can: "companies",
        onDeniedRoute: "login",
        require_auth: true,
        title: "Company",
      },
      children: [{
        name: route_names.company,
        path: "/company",
        component: () => import("@/views/app/company/Company"),
      }, ],
    },

    {
      name: route_names.package,
      path: "/package",
      component: layout,
      redirect: "/Package",
      meta: {
        can: "packages",
        onDeniedRoute: "login",
        require_auth: true,
        title: "Package",
      },
      children: [{
        name: route_names.package,
        path: "/package",
        component: () => import("@/views/app/package/Package"),
      }, ],
    },

    {
      name: route_names.city,
      path: "/city",
      component: layout,
      redirect: "/city",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "City",
      },
      children: [{
        name: route_names.city,
        path: "/city",
        component: () => import("@/views/app/city/Package"),
      }, ],
    },

    {
      name: route_names.cityArea,
      path: "/cityArea",
      component: layout,
      redirect: "/Package",
      meta: {
        can: "packages",
        onDeniedRoute: "login",
        require_auth: true,
        title: "cityArea",
      },
      children: [{
        name: route_names.package,
        path: "/cityArea",
        component: () => import("@/views/app/cityArea/Package"),
      }, ],
    },

    {
      name: route_names.department,
      path: "/department",
      component: layout,
      redirect: "/branch",
      meta: {
        can: "department",
        onDeniedRoute: "login",
        require_auth: true,
        title: "Department",
      },
      children: [{
        name: route_names.department,
        path: "/department",
        component: () => import("@/views/app/department/Department"),
      }, ],
    },
    {
      name: route_names.services,
      path: "/services",
      component: layout,
      redirect: "/services",
      meta: {
        can: "services",
        onDeniedRoute: "login",
        require_auth: true,
        title: "Services",
      },
      children: [{
        name: route_names.services,
        path: "/services",
        component: () => import("@/views/app/services/Service"),
      }, ],
    },
    {
      name: route_names.module,
      path: "/module",
      component: layout,
      redirect: "/module",
      meta: {
        can: "modules",
        onDeniedRoute: "login",
        require_auth: true,
        title: "Module",
      },
      children: [{
        name: route_names.module,
        path: "/module",
        component: () => import("@/views/app/modules/Module"),
      }, ],
    },
    {
      name: route_names.permissions,
      path: "/permissions",
      component: layout,
      redirect: "/permissions",
      meta: {
        can: "permissions",
        onDeniedRoute: "login",
        require_auth: true,
        title: "Permissions",
      },
      children: [{
        name: route_names.permissions,
        path: "/permissions",
        component: () => import("@/views/app/permissions/Permission"),
      }, ],
    },
    {
      name: "unauthorized",
      path: "/unauthorized",
      redirect: "/unauthorized",
      meta: {
        require_auth: false,
      },
      children: [{
        name: "unauthorized",
        path: "/unauthorized",
        component: () => import("@/views/auth/Unauthorized"),
      }, ],
    },

    {
      name: route_names.itemCodeTemplate,
      path: "/itemCodeTemplate",
      component: layout,
      redirect: "/itemCodeTemplate",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "itemCodeTemplate",
      },
      children: [{
        name: route_names.itemCodeTemplate,
        path: "/itemCodeTemplate",
        component: () => import("@/views/app/itemCodeTemplate/Package"),
      }, ],
    },

    {
      name: route_names.itemCombination,
      path: "/itemCombination",
      component: layout,
      redirect: "/itemCombination",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "itemCombination",
      },
      children: [{
        name: route_names.itemCombination,
        path: "/itemCombination",
        component: () => import("@/views/app/itemCombination/Package"),
      }, ],
    },

    {
      name: route_names.itemSize,
      path: "/itemSize",
      component: layout,
      redirect: "/itemSize",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "itemSize",
      },
      children: [{
        name: route_names.itemSize,
        path: "/itemSize",
        component: () => import("@/views/app/itemSize/Package"),
      }, ],
    },

    {
      name: route_names.itemGroup,
      path: "/itemGroup",
      component: layout,
      redirect: "/itemGroup",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "itemGroup",
      },
      children: [{
        name: route_names.itemGroup,
        path: "/itemGroup",
        component: () => import("@/views/app/itemGroup/Package"),
      }, ],
    },

    {
      name: route_names.category,
      path: "/category",
      component: layout,
      redirect: "/category",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "category",
      },
      children: [{
        name: route_names.category,
        path: "/category",
        component: () => import("@/views/app/category/Package"),
      }, ],
    },

    {
      name: route_names.subcategory,
      path: "/subcategory",
      component: layout,
      redirect: "/subcategory",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "subcategory",
      },
      children: [{
        name: route_names.subcategory,
        path: "/subcategory",
        component: () => import("@/views/app/subcategory/Package"),
      }, ],
    },

    {
      name: route_names.dbAttribute1,
      path: "/dbAttribute1",
      component: layout,
      redirect: "/dbAttribute1",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "dbAttribute1",
      },
      children: [{
        name: route_names.dbAttribute1,
        path: "/dbAttribute1",
        component: () => import("@/views/app/dbAttribute1/Package"),
      }, ],
    },

    {
      name: route_names.dbAttribute2,
      path: "/dbAttribute2",
      component: layout,
      redirect: "/dbAttribute2",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "dbAttribute2",
      },
      children: [{
        name: route_names.dbAttribute2,
        path: "/dbAttribute2",
        component: () => import("@/views/app/dbAttribute2/Package"),
      }, ],
    },

    {
      name: route_names.calendarSeason,
      path: "/calendarSeason",
      component: layout,
      redirect: "/calendarSeason",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "calendarSeason",
      },
      children: [{
        name: route_names.calendarSeason,
        path: "/calendarSeason",
        component: () => import("@/views/app/calendarSeason/Package"),
      }, ],
    },

    {
      name: route_names.gender,
      path: "/gender",
      component: layout,
      redirect: "/gender",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "gender",
      },
      children: [{
        name: route_names.gender,
        path: "/gender",
        component: () => import("@/views/app/gender/Package"),
      }, ],
    },

    {
      name: route_names.brand,
      path: "/brand",
      component: layout,
      redirect: "/brand",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "brand",
      },
      children: [{
        name: route_names.brand,
        path: "/brand",
        component: () => import("@/views/app/brand/Package"),
      }, ],
    },

    {
      name: route_names.ibAttribute2,
      path: "/ibAttribute2",
      component: layout,
      redirect: "/ibAttribute2",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "ibAttribute2",
      },
      children: [{
        name: route_names.ibAttribute2,
        path: "/ibAttribute2",
        component: () => import("@/views/app/ibAttribute2/Package"),
      }, ],
    },

    {
      name: route_names.ibAttribute5,
      path: "/ibAttribute5",
      component: layout,
      redirect: "/ibAttribute5",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "ibAttribute5",
      },
      children: [{
        name: route_names.ibAttribute5,
        path: "/ibAttribute5",
        component: () => import("@/views/app/ibAttribute5/Package"),
      }, ],
    },

    {
      name: route_names.ibAttribute6,
      path: "/ibAttribute6",
      component: layout,
      redirect: "/ibAttribute6",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "ibAttribute6",
      },
      children: [{
        name: route_names.ibAttribute6,
        path: "/ibAttribute6",
        component: () => import("@/views/app/ibAttribute6/Package"),
      }, ],
    },

    {
      name: route_names.ibAttribute7,
      path: "/ibAttribute7",
      component: layout,
      redirect: "/ibAttribute7",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "ibAttribute7",
      },
      children: [{
        name: route_names.ibAttribute7,
        path: "/ibAttribute7",
        component: () => import("@/views/app/ibAttribute7/Package"),
      }, ],
    },

    {
      name: route_names.itemDefinition,
      path: "/itemDefinition",
      component: layout,
      redirect: "/itemDefinition",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "itemDefinition",
      },
      children: [{
        name: route_names.itemDefinition,
        path: "/itemDefinition",
        component: () => import("@/views/app/itemDefinition/Company"),
      }, ],
    },

    {
      name: route_names.securityGroup,
      path: "/securityGroup",
      component: layout,
      redirect: "/securityGroup",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "securityGroup",
      },
      children: [{
        name: route_names.securityGroup,
        path: "/securityGroup",
        component: () => import("@/views/app/role/Package"),
      }, ],
    },

    {
      name: route_names.users,
      path: "/users",
      component: layout,
      redirect: "/users",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "users",
      },
      children: [{
        name: route_names.users,
        path: "/users",
        component: () => import("@/views/app/user/User"),
      }, ],
    },

    {
      name: route_names.groupRight,
      path: "/groupRight",
      component: layout,
      redirect: "/groupRight",
      meta: {
        can: "city",
        onDeniedRoute: "login",
        require_auth: true,
        title: "groupRight",
      },
      children: [{
        name: route_names.groupRight,
        path: "/groupRight",
        component: () => import("@/views/app/user/GroupRight"),
      }, ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  let require_auth = to.matched.some((record) => record.meta.require_auth);
  let not_require_auth = to.matched.some(
    (record) => record.meta.not_require_auth
  );
  let token = !!STORAGE.TOKEN.get();

  if (
    STORAGE.USER.get() &&
    !STORAGE.USER.get().data.is_password_verified &&
    to.name != "set-password"
  ) {
    // next({
    // name: route_names.set_password
    // })
  } else {
    next();
  }

  if (require_auth && !token) {
    next({
      name: route_names.auth,
    });
  } else if (not_require_auth && token) {
    next({
      name: route_names.home,
    });
  } else if (to.matched.length == 0 && token) {
    next({
      name: route_names.home,
    });
  } else if (to.matched.length == 0 && !token) {
    next({
      name: route_names.auth,
    });
  } else {
    next();
  }
});

export function addRoutesAgainstUser() {
  //router.addRoute(route_names.dashboard)
}
export function removeRoutesAfterUserLogout() {
  router.removeRoute(route_names.dashboard, toturials_routes);
}

export default router;
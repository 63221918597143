import Axios from "axios";
import { STORAGE } from "./store.service";
import { API_LOGGER } from "./logger.service";
import router, { route_names } from "@/router";
import Swal from "sweetalert2";

export const API = {
  TestAxios: Axios.create({
    name: "test",
    headers: {
      "Content-Type": "application/json",
    },
  }),
  FileUploadAxios: Axios.create({
    name: "fileAxio",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }),
  init() {
    this.TestAxios.interceptors.request.use(this.onRequest.bind(this));
    this.TestAxios.interceptors.response.use(
      this.onSuccess.bind(this),
      this.onError.bind(this)
    );

    this.FileUploadAxios.interceptors.request.use(this.onRequest.bind(this));
    this.FileUploadAxios.interceptors.response.use(
      this.onSuccess.bind(this),
      this.onError.bind(this)
    );
  },
  onRequest(config) {
    API_LOGGER.info(config?.url, config);
    const api_name = config?.name;
    const token = STORAGE.TOKEN.get();

    if (api_name == "test" || api_name == "fileAxio") {
      config.baseURL = process.env.VUE_APP_BASE_URL;
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  getWebUrl() {
    let webUrl = process.env.VUE_APP_WEB_BASE_URL;
    return webUrl;
  },
  getBucketUrl() {
    let bucketURL = process.env.VUE_APP_BUCKET_URL;
    return bucketURL;
  },
  onError(ex) {
    API_LOGGER.danger(ex?.config?.url, { ex });
    let response_error = ex?.response?.data ?? {};
    const status_code = ex?.response?.status;
    const response_message = response_error?.message;
    let default_message = "Action not perform successfully";
    let message = "";
    if (ex.message == "Network Error") {
      if (!window?.navigator?.onLine) {
        message = `Check your Internet connection`;
      } else {
        message = `Check your Internet connection`;
      }
    } else if (ex.message.includes("timeout")) {
      message = `It might be because of network or server error, Please contact our support team`;
    } else if (status_code == 500) {
      message = default_message;
    } else if (status_code == 401) {
      Swal.fire("Error!", response_message, "error");
      STORAGE.TOKEN.set("");
      STORAGE.USER.remove();
      router.push({
        name: route_names.login,
      });
    } else {
      message = response_message || ex.message || default_message;
    }

    const response = {
      own_message: message ?? default_message,
      own_errors: {},
      response_error: response_error,
      ...ex,
      message: ex.message ?? "",
      stack: ex.stack ?? "",
    };

    API_LOGGER.info(response);
    throw response;
  },
  onSuccess(response) {
    API_LOGGER.success(response?.config?.url, response);
    return response;
  },
  endpoints: {
    auth: {
      login(data) {
        return API.TestAxios.request({
          url: "login",
          method: "POST",
          data,
        });
      },
      updateuserpass(data) {
        return API.TestAxios.request({
          url: "user/update-password",
          method: "POST",
          data,
        });
      },
      register(data) {
        return API.TestAxios.request({
          url: "register",
          method: "POST",
          data,
        });
      },
      forgetpassword(data) {
        return API.TestAxios.request({
          url: "login",
          method: "POST",
          data,
        });
      },
      resetpassword(data) {
        return API.TestAxios.request({
          url: "password/reset",
          method: "POST",
          data,
        });
      },
    },
    user: {
      getbyid() {
        return API.TestAxios.request({
          method: "GET",
          url: `profile`,
        });
      },
      getUserDetailById(id) {
        return API.TestAxios.request({
          method: "GET",
          url: `users/${id}`,
        });
      },
      listing(page){
        return API.TestAxios.request({
          method: 'GET',
          url: `users?page=`+page,
        })
      },
      addUser(data){
        return API.TestAxios.request({
          url: `add-user`,
          method: "POST",
          data,
        });
      },
      forgetUserPassword(data) {
        return API.TestAxios.request({
          url: `password/forgot`,
          method: "POST",
          data,
        });
      },
      getUsersByUserType(userType) {
        return API.TestAxios.request({
          method: "GET",
          url: `users-by-user-type/` + userType,
        });
      },
      updateUser(id,data){
        return API.TestAxios.request({
          url: `users/` + id,
          method: "PATCH",
          data,
        });
      },
      changeUserPassword(data) {
        return API.TestAxios.request({
          url: `change-user-password`,
          method: "POST",
          data,
        });
      },
      setPassword(data) {
        return API.TestAxios.request({
          url: "set-password",
          method: "POST",
          data,
        });
      },
    },companies: {
      listing(page, params, search) {
        let url =
          params == "" && search == ""
            ? `companies?page=` + page
            : `companies?page=` + page + "&search=" + params + ":" + search;
        return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      },
      allCompanies() {
        return API.TestAxios.request({
          method: "GET",
          url: `companies?skipPagination=true`,
        });
      },
      addCompany(data) {
        return API.FileUploadAxios.request({
          url: `companies`,
          method: "POST",
          data,
        });
      },
      updateCompany(data) {
        return API.FileUploadAxios.request({
          url: `companies/` + data.id,
          method: "POST",
          data,
        });
      },
      editCompaniePermissions(user_id, permissions) {
        return API.TestAxios.request({
          url: `companiepermissions`,
          method: "POST",
          data: {
            user_id: user_id,
            permissions: permissions,
          },
        });
      },
      updateCompanyConfig(id, data) {
        return API.TestAxios.request({
          url: `configuration/update/` + id ,
          method: "POST",
          data: {
            config: data,
          },
        });
      },
    },  
    permissions: {
      listing(page) {
        return API.TestAxios.request({
          method: "GET",
          url: `permissions?page=` + page,
        });
      },
      fetchPermissionsForGroup(user_id = "") {
        return API.TestAxios.request({
          method: "GET",
          url: `permissions?flag=true&group=` + user_id,
        });
      },
      fetchGroupPermissions(data) {
        return API.TestAxios.request({
          method: "POST",
          url: `permissions-grouped`,
          data,
        });
      },
      updateGroupPermissions(data) {
        return API.TestAxios.request({
          method: "PUT",
          url: `roles/`+data.role_id+'/permission',
          data,
        });
      },
    },
    packages:{
      listing(page, params, search) {
        let url =
          params == "" && search == ""
            ? `packages?page=` + page
            : `packages?page=` + page + "&search=" + params + ":" + search;
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      },
      addPackage(data) {
        return API.FileUploadAxios.request({
          url: `packages`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `packages/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `packages/`+id,
        });
      }
      
    },
    department: {
      listing(page, params, search,companyFilter) {
        let url =
          params == "" && search == ""
            ? `departments?page=` + page
            : `departments?page=` + page + "&search=" + params + ":" + search;
        if(companyFilter){
          url += "&company_id="+companyFilter;
        }

        return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      },
      allDepartments() {
        return API.TestAxios.request({
          method: "GET",
          url: `departments?skipPagination=true`,
        });
      },
      addDepartment(data) {
        return API.TestAxios.request({
          url: `departments`,
          method: "POST",
          data,
        });
      },
      updateDepartment(data) {
        return API.TestAxios.request({
          method: "PATCH",
          url: `departments/` + data.id,
          data,
        });
      },
      processedListing(page, data) {
        return API.TestAxios.request({
          url: `departments/processed?page=` + page,
          method: "POST",
          data,
        });
      },
      updateMakerRequestStatus(data) {
        return API.TestAxios.request({
          url: `departments/processed/status`,
          method: "POST",
          data,
        });
      },
    },
    cities:{
      listing(page, params, search) {
        let url =
          params == "" && search == ""
            ? `cities?page=` + page
            : `cities?page=` + page + "&search=" + params + ":" + search;
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      },
      allCities() {
        return API.TestAxios.request({
          method: "GET",
          url: `cities?skipPagination=true`,
        });
      },
      addPackage(data) {
        return API.FileUploadAxios.request({
          url: `cities`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `cities/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `cities/`+id,
        });
      }
      
    },
    cityAreas:{
      listing(page, params, search) {
        let url =
          params == "" && search == ""
            ? `city-areas?page=` + page
            : `city-areas?page=` + page + "&search=" + params + ":" + search;
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      },
      addPackage(data) {
        return API.FileUploadAxios.request({
          url: `city-areas`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `city-areas/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `city-areas/`+id,
        });
      }
      
    },
    templatecodes:{
      listing(page, params, search) {
        let url =
          params == "" && search == ""
            ? `template-codes?page=` + page
            : `template-codes?page=` + page + "&search=" + params + ":" + search;
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      },
      allCities() {
        return API.TestAxios.request({
          method: "GET",
          url: `template-codes?skipPagination=true`,
        });
      },
      addPackage(data) {
        return API.FileUploadAxios.request({
          url: `template-codes`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `template-codes/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `template-codes/`+id,
        });
      }
      
    },
    itemcombination:{
      listing(page, params, search) {
        let url =
          params == "" && search == ""
            ? `item-combinations?page=` + page
            : `item-combinations?page=` + page + "&search=" + params + ":" + search;
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      },
      allItemCombination() {
        return API.TestAxios.request({
          method: "GET",
          url: `item-combinations?skipPagination=true`,
        });
      },
      addPackage(data) {
        return API.FileUploadAxios.request({
          url: `item-combinations`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `item-combinations/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `item-combinations/`+id,
        });
      }
      
    },
    itemsize:{
      listing(page, params, search) {
        let url =
          params == "" && search == ""
            ? `item-sizes?page=` + page
            : `item-sizes?page=` + page + "&search=" + params + ":" + search;
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      },
      allItemSizes() {
        return API.TestAxios.request({
          method: "GET",
          url: `item-sizes?skipPagination=true`,
        });
      },
      addPackage(data) {
        return API.FileUploadAxios.request({
          url: `item-sizes`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `item-sizes/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `item-sizes/`+id,
        });
      }
      
    },
    itemgroup:{
      listing(page, params, search) {
        let url =
          params == "" && search == ""
            ? `item-groups?page=` + page
            : `item-groups?page=` + page + "&search=" + params + ":" + search;
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      },
      allitemgroup() {
        return API.TestAxios.request({
          method: "GET",
          url: `item-groups?skipPagination=true`,
        });
      },
      addPackage(data) {
        return API.FileUploadAxios.request({
          url: `item-groups`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `item-groups/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `item-groups/`+id,
        });
      }
      
    },

    category:{
      listing(page, params, search) {
        let url =
          params == "" && search == ""
            ? `categories?page=` + page
            : `categories?page=` + page + "&search=" + params + ":" + search;
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      },      allCategories() {
        return API.TestAxios.request({
          method: "GET",
          url: `categories?skipPagination=true`,
        });
      },
      addPackage(data) {
        return API.FileUploadAxios.request({
          url: `categories`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `categories/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `categories/`+id,
        });
      }
      
    },

    subcategory:{
      listing(page, params, search) {
        let url =
          params == "" && search == ""
            ? `sub-categories?page=` + page
            : `sub-categories?page=` + page + "&search=" + params + ":" + search;
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      },
      allSubCategories(type) {
        return API.TestAxios.request({
          method: "GET",
          url: `sub-categories?skipPagination=true&category=`+type,
        });
      },
      addPackage(data) {
        return API.FileUploadAxios.request({
          url: `sub-categories`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `sub-categories/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `sub-categories/`+id,
        });
      }
      
    },


    dbAttribute:{
      listing(type,page, params, search) {
        let url =
          params == "" && search == ""
            ? `db-attriubutes?type=`+type+`&page=` + page
            : `db-attriubutes?type=`+type+`&page=` + page + "&search=" + params + ":" + search;
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      },      alldbattributes() {
        return API.TestAxios.request({
          method: "GET",
          url: `db-attriubutes?skipPagination=true`,
        });
      },
      addPackage(data) {
        return API.FileUploadAxios.request({
          url: `db-attriubutes`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `db-attriubutes/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `db-attriubutes/`+id,
        });
      }
      
    },
    

    calendarSeason:{
      listing(page, params, search) {
        let url =
          params == "" && search == ""
            ? `calendar-seasons?&page=` + page
            : `calendar-seasons?&page=` + page + "&search=" + params + ":" + search;
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      }, 
      allCalendarSeason() {
        return API.TestAxios.request({
          method: "GET",
          url: `calendar-seasons?skipPagination=true`,
        });
      },
      addPackage(data) {
        return API.FileUploadAxios.request({
          url: `calendar-seasons`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `calendar-seasons/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `calendar-seasons/`+id,
        });
      }
      
    },

    gender:{
      listing(page, params, search) {
        let url =
          params == "" && search == ""
            ? `genders?&page=` + page
            : `genders?&page=` + page + "&search=" + params + ":" + search;
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      }, 
      allGender() {
        return API.TestAxios.request({
          method: "GET",
          url: `genders?skipPagination=true`,
        });
      },
      addPackage(data) {
        return API.FileUploadAxios.request({
          url: `genders`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `genders/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `genders/`+id,
        });
      }
      
    },

    brand:{
      listing(page, params, search) {
        let url =
          params == "" && search == ""
            ? `brands?&page=` + page
            : `brands?&page=` + page + "&search=" + params + ":" + search;
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      }, 
      allBrand() {
        return API.TestAxios.request({
          method: "GET",
          url: `brands?skipPagination=true`,
        });
      },
      addPackage(data) {
        return API.FileUploadAxios.request({
          url: `brands`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `brands/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `brands/`+id,
        });
      }
      
    },
    
    supplier:{
      listing(page, params, search) {
        let url =
          params == "" && search == ""
            ? `suppliers?&page=` + page
            : `suppliers?&page=` + page + "&search=" + params + ":" + search;
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      }, 
      allsupplier() {
        return API.TestAxios.request({
          method: "GET",
          url: `suppliers?skipPagination=true`,
        });
      },
      addPackage(data) {
        return API.FileUploadAxios.request({
          url: `suppliers`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `suppliers/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `suppliers/`+id,
        });
      }
      
    },

    ibAttribute:{
      listing(type,page, params, search) {
        let url =
          params == "" && search == ""
            ? `item-base-attributes?type=`+type+`&page=` + page
            : `item-base-attributes?type=`+type+`&page=` + page + "&search=" + params + ":" + search;
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      },
      allibAttribute(type) {
        return API.TestAxios.request({
          method: "GET",
          url: `item-base-attributes?skipPagination=true&type=`+type,
        });
      },
      addPackage(data) {
        return API.FileUploadAxios.request({
          url: `item-base-attributes`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `item-base-attributes/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `item-base-attributes/`+id,
        });
      }
      
    },

    items:{
      listing(page, params, search) {
        let url =
          params == "" && search == ""
            ? `items?page=` + page
            : `items?page=` + page + "&search=" + params + ":" + search;
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      },
      addPackage(data) {
        return API.TestAxios.request({
          url: `items`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `items/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `items/`+id,
        });
      }
      
    },

    role:{
      listing(page, params, search) {
        let url = `roles?page=` + page
          return API.TestAxios.request({
          method: "GET",
          url: url,
        });
      },
      allRole() {
        return API.TestAxios.request({
          method: "GET",
          url: `roles?skipPagination=true`,
        });
      },
      addPackage(data) {
        return API.TestAxios.request({
          url: `roles`,
          method: "POST",
          data,
        });
      },
      updatePackage(data){
        return API.TestAxios.request({
          url: `roles/` + data.id,
          method: "PATCH",
          data,
        });
      },
      delete(id){
        return API.TestAxios.request({
          method: "DELETE",
          url: `roles/`+id,
        });
      }
      
    },
  }
}